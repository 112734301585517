import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { ApplicationLayout } from 'shared/components/ApplicationLayout';
import { UserRole } from 'shared/models';
import { useAccounts } from 'shared/store/modules/accounts/hooks';
import { selectRole, selectIsStreamlinedProspect } from 'shared/store/modules/auth/selectors';
import Loading from '../Loading';

const SessionComponent = withAuthenticationRequired(
  ({ loginWithRedirect }: any) => {
    const history = useHistory();
    const userRole = useSelector(selectRole);
    const isStreamlinedProspect = useSelector(selectIsStreamlinedProspect);
    const { activeAccountId } = useAccounts();

    if (history.location.pathname === '/' && !userRole) {
      loginWithRedirect({
        redirect_uri: `${window.location.origin}/after-login`
      });
    } else {
      if (userRole === UserRole.Prospect) {
        if (isStreamlinedProspect === true) return <Redirect to="/signup/next" />;
        return <Redirect to="/residential/next" />;
      }

      if (!activeAccountId && userRole !== UserRole.Admin) {
        return <Redirect to="/accounts" />;
      }

      return <ApplicationLayout />;
    }
    return <Loading />;
  },
  { returnTo: window.location.pathname }
);

export const Session = () => {
  const { error, loginWithRedirect } = useAuth0();

  if (error) {
    console.error(error);

    // @ts-ignore
    return <Redirect to={`/login?error=${error.message}&error_type=${error.error}`} />;
  }

  return <SessionComponent loginWithRedirect={loginWithRedirect} />;
};
